.Splash {
	background-color: var(--color-bg-2);
}

.SplashInner {
	max-width: var(--site-width);
	padding: 2rem 2rem;
	margin: 0 auto;
}

.SplashTitle {
	font-size: 3rem;
	text-align: center;
	margin-bottom: 1rem;
}

.SplashSubtitle {
	font-size: 1.8rem;
	text-align: center;
}

.ArticleLink {
	margin-bottom: 1rem;
}

.ArticleTitle {
	font-size: 1.4rem;
}

.ArticleDate {
	color: rgba(0, 0, 0, 0.6);
	padding-left: 0.5rem;
}