.Article {
	margin-bottom: 3rem;
}

.Title {
	font-size: 2rem;
	margin-bottom: 0.25rem;
}

.Date {
	color: rgba(0, 0, 0, 0.6);
	margin-bottom: 1rem;
	padding-left: 0.5rem;
}