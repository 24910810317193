* {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	background: none;
	text-decoration: none;
	border: none;
	color: inherit;
	font: inherit;
	line-height: inherit;
}

html {
	box-sizing: border-box;
	height: 100%;
}

body {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

li {
	list-style: none;
}

:global #app {
	flex: 1 0;
	display: flex;
	flex-direction: column;
}