.wrapper {
	line-height: 1.6;
}

.h1 {
	font-size: 2rem;
}

.h2 {
	font-size: 1.5rem;
	font-weight: 600;
}

.h3 {
	font-size: 1.2rem;
	font-weight: 600;
}

.ul, .ol {
	padding-left: 1.5em;
}

.p, .ul, .ol, .pre, .blockquote {
	margin-bottom: 1.4em;
}

.em {
	font-style: italic;
}

.strong {
	font-weight: bold;
}

.del {
	text-decoration: line-through;
}

.a {
	color: var(--color-highlight-1);
}

.a:hover, .a:focus {
	text-decoration: underline;
}

.pre, .inlineCode {
	font-family: monospace;
}

.pre {
	background-color: rgba(0, 0, 0, 0.05);
	padding: 0.5em;
	line-height: 1.2;
	overflow-x: auto;
}

.blockquote {
	padding-left: 1em;
	margin-left: 0.25em;
	border-left: 4px solid rgba(0, 0, 0, 0.2);
}

ul li {
	list-style: disc;
}