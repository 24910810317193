.Header {
	flex: 0 0;
	background-color: var(--color-site-header-bg);
	color: var(--color-site-header-fg);
	font-weight: 200;
	font-size: 1.2rem;
	margin-bottom: 2rem;
}

.HeaderInner {
	max-width: var(--site-width);
	padding: 0 var(--site-padding);
	margin: 0 auto;

	display: flex;
	flex-wrap: wrap;
	/*justify-content: center;*/
	align-items: baseline;
}

.Title {
	padding: 1rem 1rem 1rem 0;
	font-size: 1.6rem;
}

.Nav {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
}

.Nav > a {
	padding: 1rem;
}

.ActiveNavLink {
	border-bottom: 1px solid var(--color-highlight-1);
}