.Footer {
	flex: 0 0;
	background-color: var(--color-site-footer-bg);
	color: var(--color-site-footer-fg);
	margin-top: 2rem;
}

.FooterInner {
	max-width: var(--site-width);
	padding: 2rem var(--site-padding);
	margin: 0 auto;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.Item {
	display: flex;
	align-items: center;
	padding: 1rem;
}

.Icon {
	height: 2em;
	margin-right: 0.25em;
	filter: invert(0.9);
	transition: transform 150ms ease-in-out;
}

.Icon:hover {
	transform: scale(1.1);
}