:root {
	--site-width: 40rem;
	--site-padding: 0;

	--font-weight-1: 300;
	--font-weight-2: 400;

	--color-text-dark: #1c1e21;
	--color-text-light: #f6f8fc;

	--color-site-bg: #fff;
	--color-site-fg: var(--color-text-dark);

	--color-site-header-bg: #c43b3d;
	--color-site-header-fg: var(--color-text-light);

	--color-site-footer-bg: #883839;
	--color-site-footer-fg: var(--color-text-light);

	--color-highlight-1: #e13835;
}

@media (max-width: 1020px) {
	:root {
		--site-padding: 1rem;
	}
}

:root {
	font-family: Poppins, sans-serif;
	font-weight: 400;
	font-kerning: normal;
	font-size: 20px;
	line-height: 1.4;
	word-wrap: break-word;
}

body {
	background-color: var(--color-site-bg);
	color: var(--color-site-fg);
}

.SiteTitle {
	font-size: 2.8rem;
}